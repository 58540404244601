<template>
  <div :id="viewCSSId" :class="viewCSSClasses" ref="scene">
    <div class="section-foreground container">
      <div class="layout-row cols">
        <div
          class="col col-1 layout-column layout-align-center-end col-padding"
        >
          <section-title
            :partNo="part.id"
            :partTitle="part.title"
            data-depth="2.5"
            class="text-right parallax-item parallax-relative"
            :show="showTitle"
          ></section-title>
          <lead
            id="lead-enquire"
            :show="showLead"
            class="parallax-item parallax-relative"
            data-depth="1.8"
            :textAlign="'right'"
            :timescale="1"
            >{{ data.meta.description }}</lead
          >
          <button-primary
            class="parallax-relative parallax-item"
            data-depth="1.5"
            :show="showDiagram"
            text="Go to Chapters"
          ></button-primary>
        </div>
        <div class="col col-2 layout-align-start-start"></div>
      </div>
    </div>

    <background-image-full
      :image="data.images.background"
      :show="showBackground"
      :imageCss="{ objectPosition: 'center center' }"
      :mask="true"
      :maskOpacity="0.9"
      :timescaleReverse="2.5"
      :timescale="1.17"
    ></background-image-full>

    <div class="container fixed fluid">
      <decal
        id="decal-foot"
        class="absolute"
        :show="showDecal"
        :image="data.images.decal"
        :parallaxDepth="1.5"
        :customTimeline="timelines.timelineFoot"
      ></decal>
    </div>
  </div>
</template>

<script>
import { chapterPart } from "@/mixins/core.js";

import SectionTitle from "@/components/layout/SectionTitle.vue";
import Lead from "@/components/ui/Lead.vue";
import ButtonPrimary from "@/components/ui/ButtonPrimary.vue";
import BackgroundImageFull from "@/components/ui/BackgroundImageFull.vue";
import Decal from "@/components/ui/Decal.vue";

export default {
  name: "part404",
  metaInfo() {
    return {
      title: "404 - Page Not Found",
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.data.meta.description,
        },
        {
          vmid: "robots",
          name: "robots",
          content: "noindex, nofollow",
        },
      ],
    };
  },
  mixins: [chapterPart],
  components: {
    SectionTitle,
    Lead,
    ButtonPrimary,
    BackgroundImageFull,
    Decal,
  },
  data() {
    return {
      doParallax: true,
      itemsToShow: [
        ["showDelay", 0],
        ["showBackground", 1400],
        ["showTitle", 200],
        ["showLead", 0],
        ["showDiagram", 0],
        ["showDecal", 1650],
      ],
      timelines: {
        timelineFoot: {
          duration: 0.8,
          animations: {
            ease: Power1.easeInOut,
            autoAlpha: 0,
            x: "+=30%",
            scale: "1",
          },
        },
      },
    };
  },
};
</script>

<style scoped>
.col-1 {
  max-width: 60%;
  width: 100%;
  margin-bottom: 2rem;
}
.col-2 {
  max-width: 43%;
}
.section-decal {
  right: -1% !important;
  bottom: -1vh !important;
  left: auto !important;
  top: auto !important;
  margin: 0;
}

/deep/ .section-decal img {
  height: 102vh;
  width: auto;
  max-width: none;
}

/deep/ .section-background-mask {
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.5) 30%,
    rgba(255, 255, 255, 0.7) 100%
  );
}
/deep/ .section-nav-mask .section-footer-mask {
  background: rgba(255, 255, 255, 0.7);
}

.portrait .col-2 {
  display: none;
}
.portrait .section-decal {
  display: none;
}
.portrait .col-1 {
  max-width: 70%;
}

/*=============================================
=                  MEDIA QUERIES             =
=============================================*/
@media screen and (max-width: 991px) {
  .col-1 {
    margin-bottom: 1rem;
  }
  /deep/ .lead {
    max-width: 13em;
  }
}
@media screen and (max-width: 479px) {
  .portrait .col-1 {
    max-width: 80%;
  }
}
</style>
