<template>
  <div class="part-component part-button">
    <div class="home-button" ref="button">
      <router-link class="btn btn-primary" :to="to">
        <span>{{ text }}</span>
        <i class="ai-right"></i>
      </router-link>
    </div>
  </div>
</template>

<script>
import { partComponent } from "@/mixins/core.js";

export default {
  name: "ButtonPrimary",
  mixins: [partComponent],
  props: {
    show: Boolean,
    text: {
      type: String,
      default: "Continue",
    },
    to: {
      type: String,
      default: "/chapters/1-new-systems-for-living/",
    },
  },
  data() {
    return {};
  },
  methods: {
    createTimeline() {
      const { button } = this.$refs;
      const tl = new TimelineMax({
        paused: true,
      });

      tl.from(button, 0.8, {
        autoAlpha: 0,
        y: "+=35",
        ease: Power1.easeOut,
      });

      this.timeline = tl;
    },
  },
};
</script>

<style scoped>
.part-button {
  font-size: calc(1.412 * var(--button-rem));
  margin: 2.124em 0;
}
/*=============================================
=                  MEDIA QUERIES             =
=============================================*/
@media screen and (max-width: 767px) {
  .part-button {
    font-size: calc(1.35 * var(--button-rem));
    margin: 1.5em 0;
  }
}

@media screen and (max-width: 520px) {
  .part-button {
    font-size: calc(1.25 * var(--button-rem));
    margin: 1.3em 0;
  }
}
</style>
